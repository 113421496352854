<template>
  <div class="manage-stats" :class="{skeleton: !state.loaded}">
    <div class="row">
      <div class="col-lg-6">
        <div class="subject font-sm clearfix pb-2">
          <span>유형별 투자자 수</span>
          <span class="font-xs float-right">(합계 {{ $lib.getNumberFormat(project.subscribeCount) }}명)</span>
        </div>
        <table class="table">
          <thead class="thead-light">
          <tr>
            <th>
              <span>일자</span>
            </th>
            <th class="text-right">
              <span>투자자 수</span>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(d, idx) in state.stats.investors" :key="idx">
            <td>
              <span>{{ d.title }}</span>
            </td>
            <td class="text-right">
              <span>{{ $lib.getNumberFormat(d.value) }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-6 mt-5 mt-lg-0">
        <div class="subject font-sm clearfix pb-2">
          <span>유형별 투자 금액</span>
          <span class="font-xs float-right">(합계 {{ $lib.getNumberFormat(project.subscribeAmount) }}원)</span>
        </div>
        <table class="table">
          <thead class="thead-light">
          <tr>
            <th>
              <span>일자</span>
            </th>
            <th class="text-right">
              <span>투자 금액</span>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(d, idx) in state.stats.amounts" :key="idx">
            <td>
              <span>{{ d.title }}</span>
            </td>
            <td class="text-right">
              <span>{{ $lib.getNumberFormat(d.value) }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import http from "@/scripts/http";
import router from "@/scripts/router";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "pageManageStats";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    project: Object
  },
  setup() {
    const component = new Component(() => {
      for (let i = 0; i < 4; i += 1) {
        for (let j in state.stats) {
          state.stats[j].push({
            title: "Wait",
            value: "0"
          });
        }
      }

      load();
    });

    const state = reactive({
      loaded: false,
      stats: {
        investors: [],
        amounts: [],
      },
    });

    const projectSeq = router.app.$route.params.projectSeq;

    const load = async () => {
      state.loaded = false;

      const promises = [
        http.get(`/api/maker/invest/${projectSeq}/stats/investor-by-sort`).catch(httpError()),
        http.get(`/api/maker/invest/${projectSeq}/stats/amount-by-sort`).catch(httpError()),
      ];

      Promise.all(promises).then((responses) => {
        state.loaded = true;
        const investors = responses[0].data.body?.length ? responses[0].data.body[0] : {};
        const amounts = responses[1].data.body?.length ? responses[1].data.body[0] : {};

        state.stats.investors = [];
        state.stats.investors.push({
          title: "일반투자자",
          value: investors?.normal || 0
        });

        state.stats.investors.push({
          title: "적격투자자",
          value: investors?.income || 0
        });

        state.stats.investors.push({
          title: "전문투자자",
          value: investors?.special || 0
        });

        state.stats.investors.push({
          title: "연고자",
          value: investors?.relative || 0
        });

        state.stats.amounts = [];
        state.stats.amounts.push({
          title: "일반투자자",
          value: amounts?.normal || 0
        });

        state.stats.amounts.push({
          title: "적격투자자",
          value: amounts?.income || 0
        });

        state.stats.amounts.push({
          title: "전문투자자",
          value: amounts?.special || 0
        });

        state.stats.amounts.push({
          title: "연고자",
          value: amounts?.relative || 0
        });
      });
    };

    return {component, state, load};
  }
});
</script>

<style lang="scss" scoped>
.manage-stats {
  padding-top: $px15;

  &.skeleton {
    > .row > div {
      .subject > span {
        @include skeleton;
      }

      table {
        th, td {
          span {
            @include skeleton;
          }
        }
      }
    }
  }
}
</style>